/**
 * Theming
 */
.fjs-container {
  --color-grey-225-10-15: hsl(225, 10%, 15%);
  --color-grey-225-10-35: hsl(225, 10%, 35%);
  --color-grey-225-10-45: hsl(225, 10%, 45%);
  --color-grey-225-10-55: hsl(225, 10%, 55%);
  --color-grey-225-10-75: hsl(225, 10%, 75%);
  --color-grey-225-10-80: hsl(225, 10%, 80%);
  --color-grey-225-10-85: hsl(225, 10%, 85%);
  --color-grey-225-10-90: hsl(225, 10%, 90%);
  --color-grey-225-10-93: hsl(225, 10%, 93%);
  --color-grey-225-10-95: hsl(225, 10%, 95%);
  --color-grey-225-10-97: hsl(225, 10%, 97%);
  --color-grey-0-0-88: hsl(0, 0%, 88%);

  --color-blue-219-100-53: hsl(219, 99%, 53%);
  --color-blue-219-100-53-05: hsla(219, 99%, 53%, 0.5);
  --color-blue-205-100-40: hsl(205, 100%, 40%);
  --color-blue-205-100-45: hsl(205, 100%, 45%);
  --color-blue-205-100-50: hsl(205, 100%, 50%);
  --color-blue-205-100-80: hsl(205, 100%, 80%);
  --color-blue-205-100-95: hsl(205, 100%, 95%);

  --color-green-150-86-44: hsl(150, 86%, 44%);

  --color-red-360-100-40: hsl(360, 100%, 40%);
  --color-red-360-100-45: hsl(360, 100%, 45%);
  --color-red-360-100-92: hsl(360, 100%, 92%);
  --color-red-360-100-95: hsl(360, 100%, 95%);
  --color-red-360-100-97: hsl(360, 100%, 97%);

  --color-white: hsl(0, 0%, 100%);
  --color-black: hsl(0, 0%, 0%);

  /**
   * Specify color variables in the following schema:
   * 1 - use specified layer
   * 2 - use layer one
   * 3 - use fallback 
   */
  --color-background: var(--cds-field, var(--cds-field-01, var(--color-white)));
  --color-background-disabled: var(--cds-background, var(--color-grey-225-10-95));
  --color-background-readonly: var(--cds-background, var(--color-grey-225-10-95));
  --color-background-adornment: var(--cds-field, var(--cds-field-01, var(--color-grey-225-10-95)));
  --color-background-inverted: var(--cds-background-inverse, var(--color-grey-225-10-90));
  --color-background-inverted-hover: var(--cds-background-inverse-hover, var(--color-grey-225-10-93));
  --color-background-active: var(--cds-background-active, var(--color-grey-225-10-75));
  --color-layer: var(--cds-layer, var(--cds-layer-01, var(--color-white)));
  --color-layer-accent: var(--cds-layer-accent, var(--color-grey-0-0-88));
  --color-background-pre: var(--cds-layer, var(--cds-layer-01, var(--color-white)));

  --color-icon-base: var(--cds-icon-primary, var(--color-black));
  --color-icon-inverted: var(--cds-icon-inverse, var(--color-black));
  --color-text: var(--cds-text-primary, var(--color-grey-225-10-15));
  --color-text-light: var(--cds-text-secondary, var(--color-grey-225-10-35));
  --color-text-lighter: var(--cds-text-secondary, var(--color-grey-225-10-45));
  --color-text-lightest: var(--cds-text-placeholder, var(--color-grey-225-10-55));
  --color-text-inverted: var(--cds-text-inverse, var(--color-text));
  --color-text-disabled: var(--cds-text-disabled, var(--color-text-light));

  --color-borders: var(--cds-border-strong, var(--cds-border-strong-01, var(--color-grey-225-10-55)));
  --color-borders-group: var(--cds-border-subtle, var(--color-grey-225-10-85));
  --color-borders-table: var(--color-borders-group);
  --color-borders-disabled: var(--cds-border-disabled, var(--color-grey-225-10-75));
  --color-borders-adornment: var(
    --cds-border-subtle,
    var(--cds-border-subtle-01, var(--color-grey-225-10-85))
  );
  --color-borders-readonly: var(--cds-border-subtle, var(--color-grey-225-10-75));
  --color-borders-inverted: var(--cds-border-inverse, var(--color-grey-225-10-90));
  --color-borders-pre: var(--cds-border-subtle, var(--color-grey-225-10-85));

  --color-warning: var(--cds-text-error, var(--color-red-360-100-45));
  --color-warning-light: var(--cds-text-error, var(--color-red-360-100-92));
  --color-accent: var(--cds-link-primary, var(--color-blue-205-100-40));
  --color-accent-readonly: var(--cds-border-strong, var(--cds-border-strong-01, var(--color-grey-225-10-55)));
  --color-datepicker-focused-day: var(--cds-button-primary, var(--color-grey-225-10-55));
  --color-shadow: var(--cds-shadow, var(--color-grey-225-10-85));

  --font-family: 'IBM Plex Sans', sans-serif;

  --font-size-group: 15px;
  --font-size-base: 14px;
  --font-size-input: 14px;
  --font-size-label: 12px;

  --line-height-base: 20px;
  --line-height-input: 18px;
  --line-height-label: 16px;

  --letter-spacing-base: 0.16px;
  --letter-spacing-input: 0.16px;
  --letter-spacing-label: 0.32px;

  --form-field-height: 36px;

  --border-definition: 1px solid var(--color-borders);
  --border-definition-adornment: 1px solid var(--color-borders-adornment);
  --outline-definition: 1px solid var(--cds-focus, var(--color-borders));
  --button-warning-outline-definition: 2px solid var(--color-warning);
  --border-definition-disabled: 1px solid var(--color-borders-disabled);
  --border-definition-readonly: 1px solid var(--color-borders-readonly);

  height: 100%;
}

.fjs-no-theme {
  --cds-field: initial;
  --cds-field-01: initial;
  --cds-background: initial;
  --cds-background-inverse: initial;
  --cds-background-inverse-hover: initial;
  --cds-background-active: initial;
  --cds-layer: initial;
  --cds-layer-01: initial;
  --cds-layer-02: initial;
  --cds-icon-primary: initial;
  --cds-icon-secondary: initial;
  --cds-icon-inverse: initial;
  --cds-text-primary: initial;
  --cds-text-secondary: initial;
  --cds-text-placeholder: initial;
  --cds-text-inverse: initial;
  --cds-text-disabled: initial;
  --cds-border-strong: initial;
  --cds-border-strong-01: initial;
  --cds-border-disabled: initial;
  --cds-border-subtle: initial;
  --cds-border-subtle-01: initial;
  --cds-border-inverse: initial;
  --cds-text-error: initial;
  --cds-link-primary: initial;
  --cds-button-primary: initial;
  --cds-shadow: initial;
  --cds-focus: initial;
  --cds-interactive: initial;
  --cds-border-interactive: initial;
  --cds-highlight: initial;
  --cds-field-02: initial;
  --cds-field-hover-02: initial;
  --cds-toggle-off: initial;
}

/**
 * Layout styles
 */
.fjs-container .fjs-vertical-layout {
  display: flex;
  flex-direction: column;
}

.fjs-container .fjs-columns {
  display: flex;
  flex-direction: row;
}

.fjs-container .fjs-vertical-group {
  display: flex;
  flex: 1;
  width: 100%;
}

.fjs-container .fjs-layout-row {
  flex: auto;
  padding: 9px 0;
  position: relative;
}

.fjs-container .fjs-layout-row:empty {
  display: none;
}

.fjs-container .fjs-column {
  flex-grow: 1;
}

.fjs-container img {
  max-width: 100%;
}

.fjs-container .cds--grid {
  margin-left: initial;
  margin-right: initial;
}

.fjs-container .cds--grid .cds--row {
  align-items: start;
  margin-left: 0;
  margin-right: 0;
}

.fjs-container .cds--grid .cds--grid .cds--row {
  margin-left: -1rem;
  margin-right: -1rem;
}

@media (max-width: 66rem) {
  .cds--col {
    flex-basis: unset !important;
  }
}

/**
 * Visual styles
 */
.fjs-container .fjs-form {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  letter-spacing: var(--letter-spacing-base);
  font-weight: 400;
  color: var(--color-text);
  position: relative;
  padding: 0 4px;
}

.fjs-container .fjs-form * {
  box-sizing: border-box;
}

.fjs-container .fjs-children {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 80px;
}

.fjs-container .fjs-element {
  display: flex;
  flex-direction: column;
  margin: 0 4px 5px 4px;
  padding: 2px 0px;
}

.fjs-container .fjs-form .fjs-element {
  margin: 0;
}

.fjs-container .fjs-element:first-of-type {
  margin-top: 8px;
}

.fjs-container .fjs-form-field:not(.fjs-powered-by) {
  margin: 1px 8px;
}

.fjs-container .fjs-powered-by {
  margin: 16px;
}

.fjs-container .fjs-form .fjs-element {
  margin: 0;
}

.fjs-container .fjs-input,
.fjs-container .fjs-taglist-input,
.fjs-container .fjs-textarea,
.fjs-container .fjs-select {
  color: var(--color-text);
  border-color: var(--color-borders);
  background-color: var(--color-background);
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.fjs-container .fjs-button {
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.fjs-container .fjs-input::placeholder,
.fjs-container .fjs-taglist-input::placeholder,
.fjs-container .fjs-textarea::placeholder,
.fjs-container .fjs-select > option:disabled,
.fjs-container .fjs-select [disabled],
.fjs-container .fjs-select > option:read-only,
.fjs-container .fjs-select [read-only] {
  color: var(--color-text-lightest);
  font-size: var(--font-size-input);
  line-height: var(--line-height-input);
  letter-spacing: var(--letter-spacing-input);
  opacity: 1;
}

.fjs-container .fjs-inline-label {
  align-items: center;
  display: flex;
}

.fjs-container .fjs-form-field-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  color: var(--color-text-light);
}

.fjs-container .fjs-incollapsible-label {
  min-height: var(--line-height-label);
}

.fjs-container .fjs-form-field-label,
.fjs-container .fjs-form-field-description,
.fjs-container .fjs-form-field-error {
  font-size: var(--font-size-label);
  line-height: var(--line-height-label);
  letter-spacing: var(--letter-spacing-label);
}

.fjs-container .fjs-form-field-description {
  color: var(--color-text-lighter);
}

.fjs-container .fjs-form-field-grouplike {
  padding: 10px 6px 0 6px;
  margin: 0 10px;
}

.fjs-container .fjs-form-field-grouplike .cds--grid {
  padding: 4px 16px;
}

.fjs-container
  .fjs-form-field-grouplike
  .fjs-form-field-grouplike
  .fjs-layout-column:first-child
  > .fjs-element
  > .fjs-form-field-grouplike:not(.fjs-editor-container .fjs-form-field-grouplike),
.fjs-container
  .fjs-layout-column:first-child
  > .fjs-element
  > .fjs-form-field-grouplike:not(.fjs-editor-container .fjs-form-field-grouplike) {
  margin-left: -6px;
}

.fjs-container
  .fjs-form-field-grouplike
  .fjs-form-field-grouplike
  .fjs-layout-column:last-child
  > .fjs-element
  > .fjs-form-field-grouplike:not(.fjs-editor-container .fjs-form-field-grouplike),
.fjs-container
  .fjs-layout-column:last-child
  > .fjs-element
  > .fjs-form-field-grouplike:not(.fjs-editor-container .fjs-form-field-grouplike) {
  margin-right: -6px;
}

.fjs-container
  .fjs-form-field-grouplike
  .fjs-layout-column:first-child
  > .fjs-element
  > .fjs-form-field-grouplike:not(.fjs-editor-container .fjs-form-field-grouplike) {
  margin-left: 11px;
}

.fjs-container
  .fjs-form-field-grouplike
  .fjs-layout-column:last-child
  > .fjs-element
  > .fjs-form-field-grouplike:not(.fjs-editor-container .fjs-form-field-grouplike) {
  margin-right: 11px;
}

.fjs-container .fjs-form-field-grouplike.fjs-outlined {
  outline: solid var(--color-borders-group) 2px;
}

.fjs-container .fjs-form-field-grouplike label {
  font-size: var(--font-size-label);
}

.fjs-container .fjs-form-field-grouplike .fjs-form-field-grouplike .cds--grid {
  padding-left: 2rem;
  padding-right: 2rem;
}

.fjs-container .fjs-form-field-grouplike > label {
  font-size: var(--font-size-group);
  line-height: var(--line-height-input);
  margin-left: 7px;
}

.fjs-container .fjs-form-field-checkbox .fjs-form-field-label,
.fjs-container .fjs-form-field-checklist .fjs-form-field-label:not(:first-child),
.fjs-container .fjs-form-field-radio .fjs-form-field-label:not(:first-child) {
  font-size: var(--font-size-input);
  line-height: var(--line-height-input);
  letter-spacing: var(--letter-spacing-input);
  color: var(--color-text);
}

.fjs-container .fjs-form-field-checklist .fjs-form-field-label:first-child,
.fjs-container .fjs-form-field-radio .fjs-form-field-label:first-child {
  margin-bottom: 4px;
}

.fjs-container .fjs-form-field.required label::after,
.fjs-container .fjs-form-field.required .group-title::after {
  content: '*';
}

.fjs-container .fjs-checkbox.group.required label::after,
.fjs-container .fjs-radio.required label::after {
  display: none;
}

.fjs-container .fjs-input,
.fjs-container .fjs-textarea,
.fjs-container .fjs-select {
  background-color: var(--color-background);
  font-size: var(--font-size-input);
  line-height: var(--line-height-input);
  letter-spacing: var(--letter-spacing-input);
}

.fjs-container .fjs-input-adornment {
  border-style: solid;
  border-color: var(--color-borders-adornment);
  background-color: var(--color-background-adornment);
  line-height: var(--line-height-input);
  color: var(--color-text-lighter);
  padding: 8px;
  width: auto !important;
  min-width: min(34px, 20%);
  display: flex;
  overflow: hidden;
}

.fjs-container .fjs-input-adornment span {
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fjs-container .fjs-input-adornment svg {
  margin: auto;
}

.fjs-container .fjs-form-field-spacer {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fjs-container hr {
  border: none;
  border-top: solid 2px var(--color-borders-group);
}

.fjs-container .fjs-form-field-separator hr {
  margin: 7px 2px;
}

.fjs-container .fjs-input[type='text'],
.fjs-container .fjs-input[type='email'],
.fjs-container .fjs-input[type='tel'],
.fjs-container .fjs-input[type='number'],
.fjs-container .fjs-button[type='submit'],
.fjs-container .fjs-button[type='button'],
.fjs-container .fjs-button[type='reset'],
.fjs-container .fjs-textarea,
.fjs-container .fjs-select {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
  border: var(--border-definition);
  border-radius: 3px;
}

.fjs-container .fjs-input-group .fjs-input[type='text'],
.fjs-container .fjs-input-group .fjs-input[type='email'],
.fjs-container .fjs-input-group .fjs-input[type='tel'],
.fjs-container .fjs-input-group .fjs-input[type='number'],
.fjs-container .fjs-select {
  height: var(--form-field-height);
}

.fjs-container .fjs-taglist {
  min-height: var(--form-field-height);
}

.fjs-container .fjs-datepicker,
.fjs-container .fjs-timepicker {
  width: 100%;
}

.fjs-container .fjs-datetime-subsection {
  min-width: 0;
  flex: 1;
}

.fjs-container .fjs-datetime-separator {
  width: 10px;
}

.fjs-container .fjs-timepicker-anchor {
  height: 100%;
  display: flex;
}

.fjs-container .fjs-form-field-datetime .fjs-input-adornment svg {
  color: var(--color-icon-base);
}

.fjs-container .fjs-input-group {
  display: flex;
  width: 100%;
  height: var(--form-field-height);
  margin: 4px 0;
  border: var(--border-definition);
  border-radius: 3px;
}

.fjs-container .fjs-input-group .fjs-input[type='text'],
.fjs-container .fjs-input-group .fjs-input[type='email'],
.fjs-container .fjs-input-group .fjs-input[type='tel'],
.fjs-container .fjs-input-group .fjs-input[type='number'],
.fjs-container .fjs-input-group .fjs-taglist,
.fjs-container .fjs-input-group .fjs-select {
  height: unset;
  min-width: min(60px, 40%);
  width: 100%;
}

.fjs-container .fjs-taglist .fjs-taglist-input,
.fjs-container .fjs-input-group .fjs-input {
  border: none;
  border-radius: 0;
  margin: 0;
  outline: 0;
  width: auto;
  flex: 1;
}

.fjs-container .fjs-input-group .fjs-select-display {
  line-height: var(--line-height-base);
  user-select: none;
  padding: 8px;
  flex: 1;
}

.fjs-container .fjs-input-group .fjs-select-display.fjs-select-placeholder,
.fjs-container .fjs-disabled .fjs-input-group .fjs-select-display,
.fjs-container .fjs-readonly .fjs-input-group .fjs-select-display {
  color: var(--color-text-lighter);
  line-height: var(--line-height-input);
}

.fjs-container .fjs-input-group .fjs-input-adornment {
  border-width: 0;
}

.fjs-container .fjs-input-group .fjs-input-adornment.border-left {
  border-left-width: 1px;
}

.fjs-container .fjs-input-group .fjs-input-adornment.border-right {
  border-right-width: 1px;
}

.fjs-container .fjs-input-group .fjs-input-adornment.border-radius-right {
  border-radius: 0 3px 3px 0;
}

.fjs-container .fjs-input-group .fjs-input-adornment.border-radius-left {
  border-radius: 3px 0 0 3px;
}

.fjs-container .fjs-input-group .fjs-input {
  border-radius: 3px;
}

.fjs-container .flatpickr-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.fjs-container .fjs-textarea {
  resize: none;
  overflow: hidden;
  height: 75px;
}

.fjs-container .fjs-select-cross,
.fjs-container .fjs-select-arrow {
  padding: 6px 10px;
}

.fjs-container .fjs-number-arrow-container {
  display: flex;
  flex-direction: column;
  border-radius: 0 2px 2px 0;
  width: clamp(16px, 40%, 23px);
  overflow: clip;
  border-left: var(--border-definition-adornment);
}

.fjs-container .fjs-number-arrow-separator {
  height: 1px;
  background-color: var(--color-borders-adornment);
}

.fjs-container .fjs-number-arrow-container button {
  border: none;
  flex: 1;
  color: var(--color-text);
  background-color: var(--color-background);
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
}

.fjs-container .fjs-number-arrow-container button:hover {
  background-color: var(--color-background-inverted-hover);
  color: var(--cds-text-inverse, var(--color-text));
}

.fjs-container .fjs-form-field-number.fjs-readonly .fjs-number-arrow-container {
  display: none;
}

.fjs-container .fjs-radio {
  display: flex;
  flex-direction: column;
}

.fjs-container .fjs-input[type='checkbox'],
.fjs-container .fjs-input[type='radio'] {
  margin: 6px 10px 6px 4px;
}

.fjs-container .fjs-button[type='submit'],
.fjs-container .fjs-button[type='button'] {
  color: var(--cds-text-inverse, var(--color-white));
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}

.fjs-container .fjs-button[type='reset'] {
  color: var(--color-text);
  background-color: transparent;
  border-color: var(--color-borders);
}

.fjs-container .fjs-button[type='submit'],
.fjs-container .fjs-button[type='button'],
.fjs-container .fjs-button[type='reset'] {
  min-width: 100px;
  width: auto;
}

.fjs-container .fjs-button[type='submit'],
.fjs-container .fjs-button[type='button'] {
  font-weight: 600;
}

.fjs-container .fjs-taglist:focus-within,
.fjs-container .fjs-input-group:focus-within,
.fjs-container .fjs-input[type='text']:focus,
.fjs-container .fjs-input[type='email']:focus,
.fjs-container .fjs-input[type='tel']:focus,
.fjs-container .fjs-input[type='number']:focus,
.fjs-container .fjs-button[type='submit']:focus,
.fjs-container .fjs-button[type='button']:focus,
.fjs-container .fjs-button[type='reset']:focus,
.fjs-container .fjs-textarea:focus,
.fjs-container .fjs-select:focus {
  outline: var(--outline-definition);
}

.fjs-container .fjs-input-group .fjs-input,
.fjs-container .fjs-input-group .fjs-input:focus {
  outline: none;
}

.fjs-container .fjs-input-group .fjs-input,
.fjs-container .fjs-input-group .fjs-input:focus {
  outline: none;
}

.fjs-container .fjs-button[type='submit']:focus,
.fjs-container .fjs-button[type='button']:focus {
  border-color: var(--color-accent);
}

.fjs-container .fjs-input:disabled,
.fjs-container .fjs-textarea:disabled,
.fjs-container .fjs-select:disabled,
.fjs-container .fjs-number-arrow-container.fjs-disabled button,
.fjs-container .fjs-taglist.fjs-disabled,
.fjs-container .fjs-disabled .fjs-input-group {
  color: var(--color-text-disabled);
  background-color: var(--cds-field, var(--color-background-disabled));
  border-color: var(--color-borders-disabled);
}

.fjs-container .fjs-number-arrow-container.fjs-disabled button {
  background-color: var(--cds-toggle-off, var(--color-background-disabled));
}

.fjs-container .fjs-readonly .fjs-input:read-only:not(:disabled),
.fjs-container .fjs-readonly .fjs-textarea:read-only:not(:disabled),
.fjs-container .fjs-readonly .fjs-select:read-only:not(:disabled),
.fjs-container .fjs-number-arrow-container.fjs-readonly button,
.fjs-container .fjs-taglist.fjs-readonly,
.fjs-container .fjs-readonly .fjs-input-group {
  background-color: var(--color-background-readonly);
  border-color: var(--color-borders-readonly);
}

.fjs-container .fjs-form-field-checkbox.fjs-readonly,
.fjs-container .fjs-form-field-checklist.fjs-readonly,
.fjs-container .fjs-form-field-radio.fjs-readonly {
  pointer-events: none;
}

.fjs-container .fjs-form-field-checkbox.fjs-readonly .fjs-input:not(:disabled),
.fjs-container .fjs-form-field-checklist.fjs-readonly .fjs-input:not(:disabled),
.fjs-container .fjs-form-field-radio.fjs-readonly .fjs-input:not(:disabled) {
  opacity: 0.4;
  accent-color: var(--color-accent-readonly);
}

.fjs-container .fjs-button[type='submit']:disabled,
.fjs-container .fjs-button[type='button']:disabled,
.fjs-container .fjs-button[type='reset']:disabled {
  color: var(--cds-text-on-color-disabled, var(--color-text-light));
  background-color: var(--color-background-disabled);
  border-color: var(--color-borders-disabled);
}

.fjs-container .fjs-button[type='submit']:read-only,
.fjs-container .fjs-button[type='button']:read-only,
.fjs-container .fjs-button[type='reset']:read-only {
  color: var(--text-light);
  background-color: var(--color-background-readonly);
  border-color: var(--color-borders-readonly);
}

.fjs-container .fjs-disabled .fjs-input-group .fjs-input-adornment,
.fjs-container .fjs-disabled .fjs-input-group .fjs-number-arrow-container,
.fjs-container .fjs-disabled .fjs-input-group .fjs-number-arrow-separator {
  border-color: var(--color-borders-disabled);
}

.fjs-container .fjs-disabled .fjs-input-group .fjs-number-arrow-container .fjs-number-arrow-up,
.fjs-container .fjs-disabled .fjs-input-group .fjs-number-arrow-container .fjs-number-arrow-down {
  pointer-events: none;
}

.fjs-container .fjs-readonly .fjs-input-group .fjs-input-adornment,
.fjs-container .fjs-readonly .fjs-input-group .fjs-number-arrow-container,
.fjs-container .fjs-readonly .fjs-input-group .fjs-number-arrow-separator {
  border-color: var(--color-borders-readonly);
}

.fjs-container .fjs-form-field.fjs-has-errors .fjs-input,
.fjs-container .fjs-form-field.fjs-has-errors .fjs-select,
.fjs-container .fjs-form-field.fjs-has-errors .fjs-textarea,
.fjs-container .fjs-form-field.fjs-has-errors .fjs-taglist,
.fjs-container .fjs-form-field.fjs-has-errors .fjs-input-group,
.fjs-container .fjs-form-field.fjs-has-errors .fjs-input-group .fjs-input {
  border-color: var(--color-warning);
  outline-color: var(--color-warning);
}

.fjs-container .fjs-form-field.fjs-has-errors .fjs-number-arrow-container {
  border-color: var(--color-red-360-100-92);
}

.fjs-container .fjs-form-field.fjs-has-errors .fjs-number-arrow-separator {
  background-color: var(--color-red-360-100-92);
}

.fjs-container .fjs-form-field.fjs-has-errors .fjs-number-arrow-container button {
  background-color: var(--color-red-360-100-97);
}

.fjs-container .fjs-form-field.fjs-has-errors .fjs-number-arrow-container button:hover {
  background-color: var(--color-red-360-100-95);
}

.fjs-container .fjs-form-field.fjs-has-errors .fjs-input-adornment {
  border-color: var(--color-warning-light);
}

.fjs-container .fjs-form-field-error {
  color: var(--color-warning);
}

.fjs-container .fjs-form-field-error > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.fjs-container .fjs-form-field-error > ul > li {
  list-style-type: none;
}

.fjs-container .fjs-form-field-html,
.fjs-container .fjs-form-field-text {
  overflow-wrap: break-word;
}

.fjs-container .fjs-form-field-html a,
.fjs-container .fjs-form-field-text a {
  color: var(--color-accent);
}

.fjs-container .fjs-form-field-html .fjs-disabled-link,
.fjs-container .fjs-form-field-text .fjs-disabled-link {
  pointer-events: none;
  cursor: default;
}

.fjs-container .fjs-form-field-html table,
.fjs-container .fjs-form-field-text table {
  border-collapse: collapse;
  width: auto;
}

.fjs-container .fjs-form-field-html table,
.fjs-container .fjs-form-field-html th,
.fjs-container .fjs-form-field-html td,
.fjs-container .fjs-form-field-text table,
.fjs-container .fjs-form-field-text th,
.fjs-container .fjs-form-field-text td {
  border: 1px solid var(--color-borders-table);
  padding: 8px;
}

.fjs-container .fjs-form-field-html th,
.fjs-container .fjs-form-field-text th {
  font-weight: bold;
}

.fjs-container .fjs-form-field-html td,
.fjs-container .fjs-form-field-text td {
  white-space: nowrap;
}

.fjs-container .fjs-form-field-html pre,
.fjs-container .fjs-form-field-text pre {
  border: 1px solid var(--color-borders-pre);
  background: var(--color-background-pre);
  padding: 4px;
}

.fjs-container .fjs-taglist-anchor,
.fjs-container .fjs-select-anchor,
.fjs-container .fjs-timepicker-anchor {
  position: relative;
}

.fjs-container .fjs-taglist-anchor .fjs-dropdownlist,
.fjs-container .fjs-select-anchor .fjs-dropdownlist {
  position: absolute;
  top: -5px;
}

.fjs-container .fjs-select-hidden-input {
  width: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0;
  border: none;
}

.fjs-container .fjs-taglist {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border: var(--color-borders) solid 1px;
  border-radius: 3px;
  margin: 4px 0;
  padding: 5px;
  background-color: var(--color-background);
}

.fjs-container .fjs-taglist-skip-link {
  outline: none;
  border: none;
  background-color: transparent;
  color: transparent;
  height: 0px;
  width: 0px;
  position: absolute;
  left: -100px;
}

.fjs-container .fjs-taglist-skip-link:focus {
  position: relative;
  height: auto;
  width: auto;
  border: solid 1px var(--color-accent);
  color: var(--color-accent);
  left: 0;
}

.fjs-container .fjs-taglist .fjs-taglist-tag {
  display: flex;
  overflow: hidden;
  user-select: none;
  color: var(--color-text-inverted);
  background-color: var(--color-background-inverted);
  border-radius: 2px;
}

.fjs-container .fjs-taglist.fjs-disabled .fjs-taglist-tag,
.fjs-container .fjs-taglist.fjs-readonly .fjs-taglist-tag {
  background-color: var(--color-background-inverted);
}

.fjs-container .fjs-taglist .fjs-taglist-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-label {
  padding: 2px 6px 2px 8px;
}

.fjs-container .fjs-taglist.fjs-disabled .fjs-taglist-tag .fjs-taglist-tag-label,
.fjs-container .fjs-taglist.fjs-readonly .fjs-taglist-tag .fjs-taglist-tag-label {
  padding: 2px 8px;
}

.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-remove {
  overflow: clip;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 28px;
  background-color: var(--cds-background-inverse, var(--color-grey-225-10-80));
  border: none;
  padding: 1px 0;
}

.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-remove > svg {
  margin-bottom: 4px;
  opacity: 0.6;
  color: var(--color-icon-inverted);
}

.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-remove:hover,
.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-remove:focus-visible {
  background-color: var(--color-background-active);
}

.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-remove:hover > svg,
.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-remove:focus-visible > svg {
  opacity: 1;
}

.fjs-container .fjs-taglist .fjs-taglist-tag .fjs-taglist-tag-remove:focus-visible {
  outline: none;
}

.fjs-container .fjs-taglist .fjs-taglist-input {
  border: none;
  background-color: transparent;
  min-width: 100px;
  height: 22px;
  margin: 1px 0;
  flex-grow: 1;
}

.fjs-container .fjs-dropdownlist {
  z-index: 1;
  position: absolute;
  user-select: none;
  overflow-y: auto;
  width: 100%;
  border-radius: 3px;
  margin-top: 3px;
  box-shadow: 0px 0px 5px var(--color-shadow);
  background-color: var(--color-layer);
}

.fjs-container .fjs-dropdownlist .fjs-dropdownlist-item {
  padding: 6px 8px;
  border-bottom: 1px solid var(--color-borders-inverted);
  color: var(--color-text-light);
}

.fjs-container .fjs-dropdownlist .fjs-dropdownlist-item:last-child {
  border-bottom: none;
}

.fjs-container .fjs-dropdownlist .fjs-dropdownlist-item.focused {
  background-color: var(--color-background-inverted);
  color: var(--color-text-inverted);
}

.fjs-container .fjs-dropdownlist .fjs-dropdownlist-empty {
  padding: 6px 8px;
  color: var(--color-text-lightest);
}

.fjs-container .fjs-image-container {
  width: fit-content;
  height: fit-content;
}

.fjs-container .fjs-image {
  object-fit: contain;
}

.fjs-container .fjs-iframe {
  margin: 4px 0;
  width: 100%;
  border: 1px solid var(--color-borders-readonly);
}

.fjs-container .fjs-image-placeholder,
.fjs-container .fjs-iframe-placeholder {
  margin: 4px 0;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  background: var(--color-background-readonly);
  color: var(--color-text-light);
}

.fjs-container .fjs-iframe-placeholder {
  border: 1px solid var(--color-borders-readonly);
}

.fjs-container .fjs-image-placeholder .fjs-image-placeholder-inner,
.fjs-container .fjs-iframe-placeholder .fjs-iframe-placeholder-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.fjs-container .fjs-iframe-placeholder .fjs-iframe-placeholder-text {
  font-size: var(--font-size-label);
}

.fjs-container .fjs-form-field-table {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.fjs-container .fjs-table-middle-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  border: 1px solid var(--color-borders-group);
  border-radius: 3px;
}

.fjs-container .fjs-table-middle-container.fjs-table-empty {
  border: none;
  color: var(--color-text-disabled);
  padding-left: 16px;
}

.fjs-container .fjs-table-inner-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.fjs-container .fjs-table {
  overflow-y: auto;
  border-collapse: collapse;
}

.fjs-container .fjs-table-head {
  background-color: var(--color-layer-accent);
}

.fjs-container .fjs-table-th {
  min-width: 120px;
  cursor: pointer;
}

.fjs-container .fjs-table-th-label {
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.fjs-container .fjs-table-th:focus {
  outline: var(--outline-definition);
  outline-offset: -1px;
}

.fjs-container .fjs-table-th,
.fjs-container .fjs-table-td {
  text-align: left;
  height: 32px;
  padding: 0 16px;
  vertical-align: center;
}

.fjs-container .fjs-table-body .fjs-table-tr:not(:last-child) {
  border-bottom: 1px solid var(--color-borders-group);
}

.fjs-container .fjs-table-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--color-borders-group);
}

.fjs-container .fjs-table-nav-button {
  border: unset;
  background: unset;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--color-borders-group);
}

.fjs-container .fjs-table-nav-button:first-of-type {
  margin-left: 16px;
}

.fjs-container .fjs-table-nav-button:focus {
  outline: var(--outline-definition);
  outline-offset: -1px;
}

.fjs-container .fjs-table-nav-button svg {
  width: 16px;
}

.fjs-container .fjs-table-sort-icon-asc,
.fjs-container .fjs-table-sort-icon-desc {
  width: 16px;
}

.fjs-container .fjs-repeat-row-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.fjs-container .fjs-repeat-row-rows {
  flex: 1;
  margin-right: 1rem;
}

.fjs-container .fjs-repeat-row-container .fjs-repeat-row-remove {
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 32px;
  color: var(--color-icon-base);
  align-items: center;
  justify-content: center;
  padding: 0;
}

.fjs-container .fjs-repeat-row-container .fjs-repeat-row-remove:focus-visible {
  outline: none;
}

.fjs-container .fjs-repeat-row-container .fjs-repeat-row-remove .fjs-repeat-row-remove-icon-container {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
}

.fjs-container
  .fjs-repeat-row-container
  .fjs-repeat-row-remove:focus-visible
  .fjs-repeat-row-remove-icon-container {
  outline: var(--button-warning-outline-definition);
}

.fjs-container .fjs-repeat-row-container:hover .fjs-repeat-render-footer-spacer {
  width: 24px;
}

.fjs-container .fjs-repeat-row-container .fjs-repeat-row-remove:hover,
.fjs-container .fjs-repeat-row-container .fjs-repeat-row-remove:focus-visible {
  color: var(--color-warning);
}

.fjs-container .fjs-repeat-row-collapsed {
  display: none;
}

.fjs-container .fjs-repeat-render-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 4px;
}

.fjs-container .fjs-repeat-render-footer.fjs-remove-allowed {
  margin-right: 32px;
  justify-content: space-between;
}

.fjs-container .fjs-repeat-render-footer button {
  background: none;
  border: none;
  padding: 4px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  color: var(--color-accent);
  display: flex;
  align-items: center;
}

.fjs-container .fjs-repeat-render-footer button svg {
  margin-right: 4px;
}

/**
* Flatpickr style adjustments
*/

.fjs-container .flatpickr-day.today {
  border-color: transparent;
  background-color: transparent;
  font-weight: bold;
}

.fjs-container .flatpickr-day.today:hover,
.fjs-container .flatpickr-day.today:focus {
  border-color: var(--color-borders);
  background-color: var(--color-datepicker-focused-day);
  color: var(--color-text-inverted);
}

.fjs-container .flatpickr-day.selected {
  border-color: var(--color-accent);
  background-color: inherit;
  color: inherit;
  font-weight: normal;
}

.fjs-container .flatpickr-day.selected.today {
  font-weight: bold;
}

.fjs-container .flatpickr-day.selected:hover,
.fjs-container .flatpickr-day.selected:focus {
  background-color: var(--color-accent);
  font-weight: bold;
  color: var(--color-text-inverted);
  border-color: var(--color-accent);
}

.fjs-container .flatpickr-days,
.flatpickr-weekdays {
  padding: 10px;
  width: 100%;
}

.fjs-container .flatpickr-calendar.hasTime.noCalendar {
  width: auto;
  max-width: 250px;
}

.fjs-container .flatpickr-calendar {
  width: 326px;
}

.fjs-hidden {
  display: none;
}

.fjs-container .fjs-filepicker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
