.chat-footer {
  textarea {
    &:hover {
      background: var(--text-th-gray-50);
    }

    &:focus {
      box-shadow: none;
      background: var(--text-th-gray-50);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px var(--text-th-gray-50);
    }
  }

  .preview-attachments {
    border-bottom: 1px solid #dbdbdb;

    .preview-attachment-list {
      max-width: calc(100vw - 500px);
    }
  }

  .emoji-picker {
    .epr-header {
      & > div {
        padding-bottom: 0;
      }
    }
  }
}

.channel-view {
  height: calc(100vh - 70px);
}

.avatar-shadow {
  box-shadow: 0 4px 4px var(--color-shadow-1), 0 16px 28px var(--color-shadow-2);
}
