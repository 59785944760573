.product-document-table {
  .ant-table-thead > tr > th {
    background-color: transparent;
    border-top: none;
  }

  .ant-table-thead {
    background-color: var(--color-background);
  }
}

.fields-table-document {
  .ant-table-tbody {
    display: none;
  }
}

.modal-document {
  .ant-modal-header {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}

.modal-document.ant-modal .ant-modal-content {
  border-radius: 8px;
}

.scan-document-table .ant-table-thead {
  position: static;
  z-index: auto;
}

.product-document {
  .ant-tabs-content.ant-tabs-content-top {
    height: 100%;
  }

  .ant-tabs-tabpane {
    height: 100%;
  }
}
