@use 'sass:math';

@function rem($value) {
  $remValue: math.div($value, 16) + rem;
  @return $remValue;
}

@mixin getBaseInputStyles($height) {
  color: var(--cds-text-primary);
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong);
  height: $height;
  font-size: var(--cds-body-short-01-font-size);
  font-weight: var(--cds-body-short-01-font-weight);
  line-height: var(--cds-body-short-01-line-height);
  letter-spacing: var(--cds-body-short-01-letter-spacing);

  &:focus {
    outline: 2px solid var(--cds-focus);
    outline-offset: -2px;
  }

  &::placeholder {
    color: var(--cds-text-placeholder);
  }
}

@mixin getSelectArrowStyles($arrowRightPosition) {
  color: var(--cds-text-primary);
  background-color: var(--cds-field);
  cursor: pointer;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right $arrowRightPosition bottom 50%;
}

// Global styles /////////////

.fjs-container {
  width: 100%;
  height: min-content;

  .cds--grid {
    padding: 0;
  }

  .fjs-form {
    background-color: transparent;
    color: var(--cds-text-primary);
    padding: 0;
  }

  @media (width < 66rem) {
    // Carbon lg width breakpoint
    .fjs-layout-column .fjs-form-field {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .fjs-input-group {
    margin: 0;
  }
}

// Themed icons /////////////

[data-carbon-theme='g10'] {
  .fjs-container {
    .fjs-form-field-number .fjs-input-group {
      .fjs-number-arrow-container {
        .fjs-number-arrow-down {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMxNjE2MTYiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTVIMjRWMTdIOHoiIC8+PC9zdmc+');
        }

        .fjs-number-arrow-up {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMxNjE2MTYiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTE3IDE1TDE3IDggMTUgOCAxNSAxNSA4IDE1IDggMTcgMTUgMTcgMTUgMjQgMTcgMjQgMTcgMTcgMjQgMTcgMjQgMTV6IiAvPjwvc3ZnPg==');
        }

        &.fjs-disabled .fjs-number-arrow-up {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjIsIDIyLCAyMiwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTE3IDE1TDE3IDggMTUgOCAxNSAxNSA4IDE1IDggMTcgMTUgMTcgMTUgMjQgMTcgMjQgMTcgMTcgMjQgMTcgMjQgMTV6IiAvPjwvc3ZnPg==');
        }

        &.fjs-disabled .fjs-number-arrow-down {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjIsIDIyLCAyMiwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTVIMjRWMTdIOHoiIC8+PC9zdmc+');
        }
      }
    }

    .fjs-form-field-select {
      .fjs-input-group {
        background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMxNjE2MTYiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
      }

      &.fjs-readonly {
        .fjs-input-group {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjIsIDIyLCAyMiwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
        }
      }

      &.fjs-disabled {
        .fjs-input-group {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjIsIDIyLCAyMiwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
        }
      }
    }

    .fjs-form-field-datetime {
      select {
        background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMxNjE2MTYiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
      }
    }
  }
}

[data-carbon-theme='g100'] {
  .fjs-container {
    .fjs-form-field-number .fjs-input-group {
      .fjs-number-arrow-container {
        .fjs-number-arrow-down {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmNGY0ZjQiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTVIMjRWMTdIOHoiIC8+PC9zdmc+');
        }

        .fjs-number-arrow-up {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmNGY0ZjQiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTE3IDE1TDE3IDggMTUgOCAxNSAxNSA4IDE1IDggMTcgMTUgMTcgMTUgMjQgMTcgMjQgMTcgMTcgMjQgMTcgMjQgMTV6IiAvPjwvc3ZnPg==');
        }

        &.fjs-disabled .fjs-number-arrow-up {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjQ0LCAyNDQsIDI0NCwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTE3IDE1TDE3IDggMTUgOCAxNSAxNSA4IDE1IDggMTcgMTUgMTcgMTUgMjQgMTcgMjQgMTcgMTcgMjQgMTcgMjQgMTV6IiAvPjwvc3ZnPg==');
        }

        &.fjs-disabled .fjs-number-arrow-down {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjQ0LCAyNDQsIDI0NCwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTVIMjRWMTdIOHoiIC8+PC9zdmc+');
        }
      }
    }

    .fjs-form-field-select {
      .fjs-input-group {
        background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmNGY0ZjQiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
      }

      &.fjs-readonly {
        .fjs-input-group {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjQ0LCAyNDQsIDI0NCwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
        }
      }

      &.fjs-disabled {
        .fjs-input-group {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJnYmEoMjQ0LCAyNDQsIDI0NCwgMC4yNSkiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
        }
      }
    }

    .fjs-form-field-datetime {
      select {
        background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmNGY0ZjQiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSI+PHBhdGggZD0iTTggMTFMMyA2IDMuNyA1LjMgOCA5LjYgMTIuMyA1LjMgMTMgNnoiPjwvcGF0aD48L3N2Zz4=');
      }
    }
  }
}

// Markdown styles /////////////

.fjs-container .fjs-form-field.fjs-form-field-text {
  font-size: var(--cds-body-long-01-font-size);
  font-weight: var(--cds-body-long-01-font-weight);
  line-height: var(--cds-body-long-01-line-height);
  letter-spacing: var(--cds-body-long-01-letter-spacing);

  p {
    font-size: var(--cds-body-long-01-font-size);
    font-weight: var(--cds-body-long-01-font-weight);
    line-height: var(--cds-body-long-01-line-height);
    letter-spacing: var(--cds-body-long-01-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  h1 {
    font-size: var(--cds-productive-heading-06-font-size);
    font-weight: var(--cds-productive-heading-06-font-weight);
    line-height: var(--cds-productive-heading-06-line-height);
    letter-spacing: var(--cds-productive-heading-06-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  h2 {
    font-size: var(--cds-productive-heading-05-font-size);
    font-weight: var(--cds-productive-heading-05-font-weight);
    line-height: var(--cds-productive-heading-05-line-height);
    letter-spacing: var(--cds-productive-heading-05-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  h3 {
    font-size: var(--cds-productive-heading-04-font-size);
    font-weight: var(--cds-productive-heading-04-font-weight);
    line-height: var(--cds-productive-heading-04-line-height);
    letter-spacing: var(--cds-productive-heading-04-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  h4 {
    font-size: var(--cds-productive-heading-03-font-size);
    font-weight: var(--cds-productive-heading-03-font-weight);
    line-height: var(--cds-productive-heading-03-line-height);
    letter-spacing: var(--cds-productive-heading-03-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  h5 {
    font-size: var(--cds-productive-heading-02-font-size);
    font-weight: var(--cds-productive-heading-02-font-weight);
    line-height: var(--cds-productive-heading-02-line-height);
    letter-spacing: var(--cds-productive-heading-02-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  h6 {
    font-size: var(--cds-productive-heading-01-font-size);
    font-weight: var(--cds-productive-heading-01-font-weight);
    line-height: var(--cds-productive-heading-01-line-height);
    letter-spacing: var(--cds-productive-heading-01-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  code {
    font-family: var(--cds-code-02-font-family);
    font-size: var(--cds-code-02-font-size);
    font-weight: var(--cds-code-02-font-weight);
    line-height: var(--cds-code-02-line-height);
    letter-spacing: var(--cds-code-02-letter-spacing);
    white-space: pre-wrap;
    margin-bottom: var(--cds-spacing-05);
  }

  blockquote {
    font-family: var(--cds-quotation-02-font-family);
    font-size: var(--cds-quotation-02-font-size);
    font-weight: var(--cds-quotation-02-font-weight);
    line-height: var(--cds-quotation-02-line-height);
    letter-spacing: var(--cds-quotation-02-letter-spacing);
    margin-bottom: var(--cds-spacing-05);
  }

  ul,
  ol {
    box-sizing: border-box;
    padding: 0;
    border: 0;
    margin: 0;
    list-style: none;
    margin-bottom: var(--cds-spacing-05);
  }

  ul {
    margin-left: var(--cds-spacing-05);

    li {
      position: relative;

      &:before {
        position: absolute;
        left: calc(-1 * var(--cds-spacing-05));
        content: '–';
      }
    }
  }

  ol {
    margin-left: var(--cds-spacing-05);

    li {
      position: relative;
      counter-increment: item;

      &:before {
        position: absolute;
        left: calc(-1 * var(--cds-spacing-05));
        content: counter(item) '.';
      }
    }
  }

  pre {
    margin-bottom: var(--cds-spacing-05);
  }

  div > :last-child {
    margin-bottom: 0;
  }
}

// Anchor styles /////////////

.fjs-container .fjs-form-field-text a {
  color: var(--cds-link-primary);
  outline: none;
  text-decoration: underline;
  font-size: inherit;
  font-weight: inherit;
  line-height: var(--cds-body-compact-01-line-height);
  letter-spacing: var(--cds-body-compact-01-letter-spacing);

  &:hover {
    color: var(--cds-link-primary-hover);
  }

  &:focus {
    outline: 1px solid var(--cds-focus);
  }

  &:active,
  &:active:visited,
  &:active:visited:hover {
    color: var(--cds-text-primary);
  }

  &:visited {
    color: var(--cds-link-primary);
  }

  &:visited:hover {
    color: var(--cds-link-primary-hover);
  }
}

// Read-only styles /////////////

.fjs-container {
  .fjs-readonly {
    .fjs-input:read-only:not(:disabled),
    .fjs-textarea:read-only:not(:disabled),
    .fjs-select:read-only:not(:disabled),
    &.fjs-taglist,
    .fjs-input-group {
      background-color: transparent;
    }

    &.fjs-form-field {
      .fjs-input-group {
        border-bottom: 1px solid var(--cds-border-subtle);
      }
    }

    &.fjs-form-field-number {
      .fjs-input-group .fjs-number-arrow-container {
        background-color: transparent;

        .fjs-number-arrow-up,
        .fjs-number-arrow-down {
          background-color: transparent;
          pointer-events: none;
        }
      }
    }

    &.fjs-form-field:not(.fjs-form-field-datetime) {
      .fjs-input-group .fjs-input-adornment {
        background: transparent;
      }
    }

    &.fjs-form-field-select {
      .fjs-input-group {
        cursor: unset;
        background-color: transparent;

        .fjs-input:read-only:not(:disabled) {
          border-color: transparent;
        }

        .fjs-select-display {
          color: var(--cds-text-primary);
        }
      }
    }

    &.fjs-form-field-datetime {
      .fjs-input-group {
        cursor: unset;

        .fjs-input-adornment {
          background-color: transparent;
        }

        .flatpickr-input {
          cursor: unset;
        }

        .fjs-input-adornment svg {
          cursor: unset;
        }
      }
    }

    &.fjs-form-field-checkbox,
    &.fjs-form-field-radio,
    &.fjs-form-field-checklist {
      .fjs-input:read-only {
        opacity: 1;
        background-color: transparent;

        &:before {
          border-color: var(--cds-icon-disabled);
        }
      }

      &.fjs-checked .fjs-input[type='checkbox'] {
        &:before {
          border: 1px solid var(--cds-icon-disabled);
          background: transparent;
        }

        &:after {
          border-bottom: 2px solid var(--cds-icon-primary);
          border-left: 2px solid var(--cds-icon-primary);
        }
      }
    }

    &.fjs-taglist .fjs-taglist-tag {
      .fjs-taglist-tag-label {
        padding: 2px 0px;
      }
    }

    &.fjs-form-field-dynamiclist button.fjs-repeat-render-add,
    &.fjs-form-field-dynamiclist button .fjs-repeat-row-remove-icon-container {
      color: var(--cds-text-on-color-disabled);

      &:hover {
        color: var(--cds-text-on-color-disabled);
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }
}

// Disabled styles /////////////

.fjs-container {
  .fjs-disabled {
    &.fjs-form-field-textfield .fjs-input,
    &.fjs-form-field-datetime .fjs-input,
    & .fjs-textarea:disabled,
    & .fjs-taglist.fjs-disabled,
    & .fjs-taglist.fjs-disabled .fjs-taglist-input,
    &.fjs-form-field-select .fjs-input-group.disabled,
    &.fjs-form-field-select .fjs-input-group.disabled .fjs-select-display,
    &.fjs-form-field-select .fjs-input-group.disabled .fjs-input,
    &.fjs-form-field .fjs-form-field-label,
    &.fjs-form-field.fjs-form-field-radio .fjs-form-field-label,
    &.fjs-form-field.fjs-form-field-checklist .fjs-form-field-label,
    & .fjs-form-field-description {
      color: var(--cds-text-disabled);
      cursor: var(--cursor-disabled, not-allowed);
    }

    &.fjs-form-field-textfield .fjs-input-group,
    &.fjs-form-field-datetime .fjs-input-group,
    & .fjs-textarea:disabled,
    & .fjs-taglist.fjs-disabled,
    & .fjs-taglist.fjs-disabled .fjs-taglist-input,
    &.fjs-form-field-select .fjs-input-group.disabled,
    &.fjs-form-field-select .fjs-input-group.disabled .fjs-select-display,
    &.fjs-form-field-select .fjs-input-group.disabled .fjs-input {
      border: none;
    }
  }

  .fjs-disabled.fjs-form-field-number .fjs-input-group {
    border: none;
    cursor: var(--cursor-disabled, not-allowed);
  }

  .fjs-disabled.fjs-form-field-number .fjs-input-group .fjs-input {
    cursor: var(--cursor-disabled, not-allowed);
  }

  .fjs-form-field.fjs-disabled.fjs-checked .fjs-input[type='checkbox'] {
    cursor: var(--cursor-disabled, not-allowed);
    &:before {
      border-color: var(--cds-icon-disabled);
      background-color: var(--cds-icon-disabled);
      cursor: var(--cursor-disabled, not-allowed);
    }

    &:after {
      cursor: var(--cursor-disabled, not-allowed);
    }
  }

  .fjs-form-field.fjs-disabled .fjs-input[type='checkbox'] {
    cursor: var(--cursor-disabled, not-allowed);
    &:before {
      border-color: var(--cds-icon-disabled);
      cursor: var(--cursor-disabled, not-allowed);
    }
  }

  .fjs-form-field-datetime.fjs-disabled .fjs-input-group .fjs-input-adornment svg {
    color: var(--cds-icon-disabled);
    cursor: var(--cursor-disabled, not-allowed);
  }

  .fjs-taglist.fjs-disabled .fjs-taglist-tag {
    background-color: var(--cds-layer-01);

    .fjs-taglist-tag-label {
      padding: 2px 0px;
      opacity: 0.7;
      color: var(--cds-text-on-color-disabled);
    }
  }
}

// Label, Description & Error styles /////////////

.fjs-container {
  .fjs-form-field-label {
    font-size: var(--cds-label-01-font-size);
    font-weight: var(--cds-label-01-font-weight);
    line-height: var(--cds-label-01-line-height);
    letter-spacing: var(--cds-label-01-letter-spacing);
  }

  .fjs-form-field:not(.fjs-form-field-checkbox, .fjs-form-field-grouplike, .fjs-form-field-table)
    .fjs-form-field-label:first-child {
    margin: 0;
    margin-bottom: var(--cds-spacing-03);
  }

  .fjs-form-field.fjs-form-field-radio .fjs-inline-label,
  .fjs-form-field.fjs-form-field-checklist .fjs-inline-label {
    margin: 0;
    margin-bottom: 0.1875rem;
  }

  .fjs-form-field.fjs-form-field-radio .fjs-inline-label {
    min-height: #{rem(27)};
  }

  .fjs-form-field-description {
    margin: 0;
    margin-top: var(--cds-spacing-02);
    font-size: var(--cds-helper-text-01-font-size);
    font-weight: var(--cds-helper-text-01-font-weight);
    line-height: var(--cds-helper-text-01-line-height);
    letter-spacing: var(--cds-helper-text-01-letter-spacing);
  }

  .fjs-form-field-error {
    margin: 0;
    margin-top: var(--cds-spacing-02);
    font-size: var(--cds-label-01-font-size);
    font-weight: var(--cds-label-01-font-weight);
    line-height: var(--cds-label-01-line-height);
    letter-spacing: var(--cds-label-01-letter-spacing);
  }

  .fjs-has-errors .fjs-form-field-description {
    display: none;
  }
}

// Checkbox styles /////////////

.fjs-container {
  .fjs-input[type='checkbox'],
  .fjs-input[type='checkbox']:focus {
    all: unset;
    width: rem(6);
  }

  .fjs-form-field .fjs-input[type='checkbox'] {
    position: relative;
    display: flex;
    min-height: rem(24);
    padding-top: rem(3);
    padding-left: rem(20);
    cursor: pointer;
    user-select: none;
    font-size: var(--cds-body-short-01-font-size);
    font-weight: var(--cds-body-short-01-font-weight);
    line-height: var(--cds-body-short-01-line-height);
    letter-spacing: var(--cds-body-short-01-letter-spacing);

    &:before,
    &:after {
      box-sizing: border-box;
    }

    &:before {
      position: absolute;
      top: rem(3);
      left: 0;
      width: rem(16);
      height: rem(16);
      border: 1px solid var(--cds-icon-primary);
      margin: rem(2) rem(2) rem(2) rem(3);
      background-color: transparent;
      border-radius: rem(1);
      content: '';
    }

    &:after {
      position: absolute;
      top: rem(9);
      left: rem(7);
      width: rem(9);
      height: rem(5);
      border-bottom: 2px solid var(--cds-icon-inverse);
      border-left: 2px solid var(--cds-icon-inverse);
      margin-top: rem(-3);
      background: 0 0;
      content: '';
      transform: scale(0) rotate(-45deg);
      transform-origin: bottom right;
    }
  }

  .fjs-form-field .fjs-input[type='checkbox']:focus {
    &:before {
      outline: 2px solid var(--cds-focus);
      outline-offset: rem(1);
    }
  }

  .fjs-form-field.fjs-checked .fjs-input[type='checkbox'],
  .fjs-form-field .fjs-inline-label.fjs-checked .fjs-input[type='checkbox'] {
    &:before {
      border: none;
      border-width: 1px;
      background-color: var(--cds-icon-primary);
    }

    &:after {
      transform: scale(1) rotate(-45deg);
    }
  }

  .fjs-form-field-checklist .fjs-form-field-label:not(:first-of-type) {
    font-size: var(--cds-body-short-01-font-size);
    font-weight: var(--cds-body-short-01-font-weight);
    line-height: var(--cds-body-short-01-line-height);
    letter-spacing: var(--cds-body-short-01-letter-spacing);
    color: var(--cds-text-primary);
  }

  .fjs-form-field-checkbox .fjs-form-field-label {
    font-size: var(--cds-body-short-01-font-size);
    font-weight: var(--cds-body-short-01-font-weight);
    line-height: var(--cds-body-short-01-line-height);
    letter-spacing: var(--cds-body-short-01-letter-spacing);
    color: var(--cds-text-primary);
  }
}

// Taglist styles /////////////

.fjs-container {
  .fjs-taglist {
    display: flex;
    align-items: center;
    min-height: 2.5rem;
    height: unset !important;
  }

  .fjs-taglist:focus-within {
    outline: 2px solid var(--cds-focus);
    outline-offset: -2px;
  }

  .fjs-taglist .fjs-taglist-input {
    &,
    &::placeholder {
      color: var(--cds-text-primary);
    }
  }

  .fjs-taglist .fjs-taglist-tag {
    font-size: var(--cds-label-01-font-size);
    font-weight: var(--cds-label-01-font-weight);
    line-height: var(--cds-label-01-line-height);
    letter-spacing: var(--cds-label-01-letter-spacing);
    max-width: 100%;
    padding: 0 0.5rem;
    border-radius: 0.9375rem;
    word-break: break-word;
    min-width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & .fjs-taglist-tag-label {
      padding: 0;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .fjs-taglist-tag-remove {
      all: unset;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 0;
      margin: 0 rem(-8) 0 0.125rem;
      cursor: pointer;

      & svg {
        all: unset;
        color: var(--cds-icon-inverse);
      }

      &:focus {
        background-color: transparent;
        outline: 1px solid var(--cds-focus-inverse);
      }
    }
  }
}

// Radio styles /////////////

.fjs-container {
  .fjs-form-field-radio {
    .fjs-input {
      appearance: none;
      width: 0;
      margin: 0;
      margin-right: calc(#{rem(18)} + var(--cds-spacing-03));
      position: relative;
      height: #{rem(18)};
      outline: none;

      &:focus:before {
        outline: 2px solid var(--cds-focus);
        outline-offset: 1.5px;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: #{rem(18)};
        height: #{rem(18)};
        border: 1px solid var(--cds-icon-primary);
        background-color: transparent;
        border-radius: 50%;
        content: '';
      }
    }

    .fjs-checked .fjs-input:after {
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      width: #{rem(18)};
      height: #{rem(18)};
      background-color: var(--cds-icon-primary);
      border-radius: 50%;
      content: '';
      transform: scale(0.5);
    }

    &.fjs-disabled .fjs-input:before {
      border-color: var(--cds-icon-disabled);
    }

    &.fjs-disabled .fjs-checked .fjs-input:after {
      background-color: var(--cds-icon-disabled);
    }

    .fjs-form-field-label:not(:first-of-type) {
      font-size: var(--cds-body-short-01-font-size);
      font-weight: var(--cds-body-short-01-font-weight);
      line-height: var(--cds-body-short-01-line-height);
      letter-spacing: var(--cds-body-short-01-letter-spacing);
      color: var(--cds-text-primary);
    }
  }
}

// Button styles /////////////

.fjs-container {
  .fjs-form-field.fjs-form-field-button .fjs-button,
  .fjs-form-field.fjs-form-field-filepicker .fjs-button {
    font-size: var(--cds-body-short-01-font-size);
    font-weight: var(--cds-body-short-01-font-weight);
    line-height: var(--cds-body-short-01-line-height);
    letter-spacing: var(--cds-body-short-01-letter-spacing);
    min-height: #{rem(32)};
    padding: calc(0.375rem - 3px) 60px calc(0.375rem - 3px) 12px;
    text-align: left;
    color: var(--cds-button-tertiary);
    border: 1px solid var(--cds-button-tertiary);
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: var(--cds-text-inverse);
      background-color: var(--cds-button-tertiary-hover);
    }

    &:focus {
      border-color: var(--cds-focus);
      box-shadow: inset 0 0 0 1px var(--cds-focus), inset 0 0 0 2px var(--cds-background);
      color: var(--cds-text-inverse);
      background-color: var(--cds-button-tertiary);
    }

    &:active {
      border-color: transparent;
      background-color: var(--cds-button-tertiary-active);
      color: var(--cds-text-inverse);
    }

    &:disabled {
      border: 1px solid var(--cds-button-disabled);
      background: transparent;
      box-shadow: none;
      color: var(--cds-text-on-color-disabled);
      cursor: var(--cursor-disabled, not-allowed);
      outline: none;
    }

    &[type='submit'] {
      background-color: var(--cds-button-primary);
      border: 1px solid transparent;
      color: var(--cds-text-on-color);

      &:disabled {
        background: var(--cds-button-disabled);
      }
    }
  }
}

// Number styles /////////////

.fjs-container {
  .fjs-form-field-number .fjs-input-group {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--cds-border-strong);
    height: 2.5rem;
    box-sizing: border-box;

    &:focus-within {
      outline: 2px solid var(--cds-focus);
      outline-offset: -2px;
    }

    .fjs-input {
      border-radius: 0;
      border: none;
      font-size: var(--cds-body-short-01-font-size);
      font-weight: var(--cds-body-short-01-font-weight);
      line-height: var(--cds-body-short-01-line-height);
      letter-spacing: var(--cds-body-short-01-letter-spacing);
    }

    .fjs-number-arrow-container {
      all: unset;
      border: none;
      border-radius: 0;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      background-color: var(--cds-field);

      .fjs-number-arrow-up,
      .fjs-number-arrow-down {
        width: 40px;
        height: calc(40px - 1px);
        background-color: var(--cds-field);
        color: transparent;

        &:hover {
          background-color: var(--cds-field-hover);
          cursor: pointer;
        }
      }

      &.fjs-disabled .fjs-number-arrow-up:hover,
      &.fjs-disabled .fjs-number-arrow-down:hover {
        background-color: var(--cds-field);
        cursor: not-allowed;
      }

      .fjs-number-arrow-separator {
        width: 0.0625rem;
        height: 1rem;
        background-color: var(--cds-border-subtle);
      }

      .fjs-number-arrow-down {
        background-repeat: no-repeat;
        background-position: right 50% bottom 50%;
      }

      .fjs-number-arrow-up {
        background-repeat: no-repeat;
        background-position: right 50% bottom 50%;
      }
    }
  }
}

// Datetime styles /////////////

.fjs-container {
  .fjs-form-field-datetime {
    .fjs-input.flatpickr-input {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;

      &,
      &::placeholder {
        color: var(--cds-text-primary);
      }
    }

    .fjs-input.flatpickr-input:disabled {
      color: var(--cds-text-disabled);
    }

    .fjs-input.flatpickr-input:disabled::placeholder {
      color: var(--cds-text-disabled);
    }

    select {
      border-bottom: none;
      padding-right: 2rem;

      @include getSelectArrowStyles(var(--cds-spacing-03));
      @include getBaseInputStyles(1.5rem);
    }

    .fjs-input-group {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
    }

    .fjs-input-group .fjs-input-adornment {
      border: none;
      border-radius: 0;
      background-color: var(--cds-field);
      display: flex;
      padding-right: var(--cds-spacing-05);
    }

    .fjs-input-group .fjs-input-adornment svg {
      color: var(--cds-icon-primary);
      cursor: pointer;
    }

    .flatpickr-wrapper {
      height: 100%;
      position: initial;
    }

    .fjs-timepicker.fjs-timepicker-anchor {
      position: unset;
    }

    .flatpickr-calendar.static {
      top: calc(100% + 3px);
    }

    .flatpickr-calendar .flatpickr-prev-month svg,
    .flatpickr-calendar .flatpickr-next-month svg {
      height: 16px;
    }

    .flatpickr-day.today {
      position: relative;
      color: var(--cds-link-primary);
      font-weight: 600;
      border-color: transparent;
    }

    .flatpickr-day.selected,
    .flatpickr-day.today.selected,
    .flatpickr-day.selected:hover,
    .flatpickr-day.today.selected:hover {
      background-color: var(--cds-button-primary);
      color: var(--cds-text-on-color);
    }

    .flatpickr-day:focus {
      outline: 2px solid var(--cds-focus);
      outline-offset: -2px;
    }

    .flatpickr-day.selected:focus {
      outline: 0.0625rem solid var(--cds-focus);
      outline-offset: -0.1875rem;
    }

    .flatpickr-day:hover {
      background: var(--cds-layer-hover);
    }

    .flatpickr-days,
    .flatpickr-weekdays {
      padding: unset;
      width: unset;
    }
  }
}

// Select styles /////////////

.fjs-container {
  .fjs-form-field-select .fjs-input-group {
    @include getSelectArrowStyles(var(--cds-spacing-05));
    @include getBaseInputStyles(2.5rem);

    .fjs-select-display {
      display: flex;
      align-items: center;
    }

    .fjs-select-arrow {
      color: transparent;
    }

    .fjs-select-cross {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: var(--cds-spacing-04);
      width: 2.5rem;
      height: calc(2.5rem - 1px);

      &:hover {
        background-color: var(--cds-layer-hover);
      }

      svg {
        color: var(--cds-icon-primary);
      }
    }

    .fjs-input {
      color: var(--cds-text-primary);
      background-color: var(--cds-field);
      border-radius: 0;
      border: none;
      border-bottom: 1px solid var(--cds-border-strong);
      height: 2.5rem;
      font-size: var(--cds-body-short-01-font-size);
      font-weight: var(--cds-body-short-01-font-weight);
      line-height: var(--cds-body-short-01-line-height);
      letter-spacing: var(--cds-body-short-01-letter-spacing);
    }
  }

  .fjs-form-field-select .fjs-select-anchor .fjs-dropdownlist {
    position: absolute;
    top: -4px;
  }

  .fjs-form-field-select .fjs-input-group:focus-within {
    outline: 2px solid var(--cds-focus);
    outline-offset: -2px;
  }

  .fjs-form-field-select.fjs-disabled .fjs-input-group {
    @include getSelectArrowStyles(var(--cds-spacing-05));
  }

  .fjs-has-errors.fjs-form-field-select .fjs-input-group:focus-within {
    outline: 2px solid var(--cds-focus);
    outline-offset: -2px;
  }

  .fjs-has-errors.fjs-form-field-select .fjs-input-group {
    outline: 2px solid var(--cds-text-error);
    outline-offset: -2px;
  }
}

// Remaining input styles /////////////

.fjs-container {
  .fjs-form-field-textfield .fjs-input-group,
  .fjs-form-field-datetime .fjs-input-group,
  .fjs-textarea,
  .fjs-taglist,
  .fjs-form-field-select.fjs-disabled .fjs-input-group {
    @include getBaseInputStyles(2.5rem);
  }

  .fjs-form-field-textfield .fjs-input-group,
  .fjs-form-field-datetime .fjs-input-group {
    &:focus-within {
      outline: 2px solid var(--cds-focus);
      outline-offset: -2px;
    }
  }
  .fjs-form-field-textfield .fjs-input,
  .fjs-form-field-datetime .fjs-input {
    background-color: var(--cds-field);
    color: var(--cds-text-primary);
    border-radius: 0;
  }

  .fjs-has-errors.fjs-form-field-number .fjs-input-group:focus-within,
  .fjs-has-errors.fjs-form-field-textarea .fjs-textarea:focus,
  .fjs-form-field-textfield.fjs-has-errors .fjs-input-group:focus-within,
  .fjs-form-field-textfield.fjs-has-errors .fjs-input-group:focus,
  .fjs-form-field-datetime.fjs-has-errors .fjs-input-group:focus-within,
  .fjs-form-field-datetime.fjs-has-errors .fjs-input-group:focus {
    outline: 2px solid var(--cds-focus);
    outline-offset: -2px;
  }

  .fjs-has-errors.fjs-form-field-number .fjs-input-group,
  .fjs-has-errors.fjs-form-field-textarea .fjs-textarea,
  .fjs-form-field-textfield.fjs-has-errors .fjs-input-group,
  .fjs-form-field-textfield.fjs-has-errors .fjs-input-group,
  .fjs-form-field-datetime.fjs-has-errors .fjs-input-group,
  .fjs-form-field-datetime.fjs-has-errors .fjs-input-group {
    outline: 2px solid var(--cds-text-error);
    outline-offset: -2px;
  }
}

// Dropdown styles /////////////

.fjs-container {
  .fjs-form-field-taglist .fjs-taglist-anchor .fjs-dropdownlist,
  .fjs-form-field-datetime .fjs-timepicker-anchor .fjs-dropdownlist,
  .fjs-form-field-select .fjs-select-anchor .fjs-dropdownlist {
    margin: 0;
    max-height: rem(264);
    border: none;
    background-color: var(--cds-layer);
    overflow-y: auto;
    cursor: pointer;
    border-radius: 0;
    box-shadow: 0 2px 6px var(--cds-shadow);

    & .fjs-dropdownlist-item {
      border: none;
      box-sizing: border-box;
      padding: 0;
      margin: 0 var(--cds-spacing-05);
    }

    & .fjs-dropdownlist-item:not(:first-of-type):not(:hover) {
      border-top: 1px solid var(--cds-border-subtle);
    }

    & .fjs-dropdownlist-item,
    & .fjs-dropdownlist-empty {
      font-size: var(--cds-body-short-01-font-size);
      font-weight: var(--cds-body-short-01-font-weight);
      line-height: var(--cds-body-short-01-line-height);
      letter-spacing: var(--cds-body-short-01-letter-spacing);
      height: #{rem(40)};
      color: var(--cds-text-secondary);
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      background-color: transparent;
    }

    & .fjs-dropdownlist-empty {
      color: var(--cds-text-disabled);
      cursor: default;
    }

    & .fjs-dropdownlist-item:hover,
    & .fjs-dropdownlist-item.focused {
      background-color: var(--cds-layer-hover);
      color: var(--cds-text-primary);
      margin: 0;
      padding: 0 var(--cds-spacing-05);
    }

    & .fjs-dropdownlist-item:not(:first-of-type):hover {
      padding-top: 1px;
    }

    & .fjs-dropdownlist-item.focused + .fjs-dropdownlist-item {
      border: none;
      padding-top: 1px;
    }
  }
}

// Adornment styles /////////////

.fjs-container .fjs-form-field:not(.fjs-form-field-datetime) {
  .fjs-input-group .fjs-input-adornment {
    all: unset;
    display: flex;
    align-items: center;
    background-color: var(--cds-field);
    color: var(--cds-text-secondary);
    padding: 0 var(--cds-spacing-04);
    cursor: default;

    &.border-right {
      padding-right: 0;
    }

    &.border-left {
      padding-left: 0;
    }
  }

  &.fjs-disabled .fjs-input-group .fjs-input-adornment {
    color: var(--cds-text-disabled);
  }
}

// iFrame styles ////////////

.fjs-container {
  .fjs-iframe-placeholder,
  .fjs-image-placeholder {
    background-color: var(--cds-layer);
    border-color: var(--cds-border-subtle);
    color: var(--cds-text-helper);
  }

  .fjs-iframe {
    border-color: var(--cds-border-subtle);
  }
}

// Table styles ////////////

.fjs-container {
  .fjs-form-field-table {
    row-gap: var(--cds-spacing-04);
  }

  .fjs-form-field.fjs-form-field-table .fjs-form-field-label {
    color: var(--cds-text-secondary);
    font-size: var(--cds-label-02-font-size);
    font-weight: var(--cds-label-02-font-weight);
    line-height: var(--cds-label-02-line-height);
    letter-spacing: var(--cds-label-02-letter-spacing);
    margin-bottom: 0;
  }

  .fjs-table-th {
    color: var(--cds-text-primary);
    font-size: var(--cds-heading-compact-01-font-size);
    font-weight: var(--cds-heading-compact-01-font-weight);
    line-height: var(--cds-heading-compact-01-line-height);
    letter-spacing: var(--cds-heading-compact-01-letter-spacing);

    &:focus {
      outline: 2px solid var(--cds-focus);
      outline-offset: -2px;
    }
  }

  .fjs-table-tr,
  .fjs-table-nav {
    block-size: rem(24px);
  }

  .fjs-table-td,
  .fjs-table-th {
    vertical-align: middle;
  }

  .fjs-table-middle-container.fjs-table-empty {
    background-color: transparent;
    color: var(--cds-text-secondary);
    padding-left: var(--cds-spacing-05);
    font-size: var(--cds-heading-compact-01-font-size);
    font-weight: var(--cds-heading-compact-01-font-weight);
    line-height: var(--cds-heading-compact-01-line-height);
    letter-spacing: var(--cds-heading-compact-01-letter-spacing);
  }

  .fjs-table-middle-container {
    border-radius: 0;
    background-color: var(--cds-layer);
  }

  .fjs-table-body .fjs-table-tr:hover {
    background-color: var(--cds-layer-hover);
  }

  .fjs-table-nav {
    color: var(--cds-text-primary);
    font-size: var(--cds-label-01-font-size);
    font-weight: var(--cds-label-01-font-weight);
    line-height: var(--cds-label-01-line-height);
    letter-spacing: var(--cds-label-01-letter-spacing);
  }

  .fjs-table-nav-button {
    color: var(--cds-text-primary);

    &:disabled {
      color: var(--cds-text-disabled);
    }

    &:focus {
      outline: 2px solid var(--cds-focus);
      outline-offset: -2px;
    }
  }

  // To be removed when this issue is fixed upstream: https://github.com/carbon-design-system/carbon/issues/14597

  .fjs-table-body .fjs-table-tr:not(:last-child) {
    border-bottom: 1px solid var(--cds-border-subtle-02);
  }

  .fjs-table-middle-container {
    border: 1px solid var(--cds-border-subtle-02);
  }

  .fjs-table-nav {
    border-top: 1px solid var(--cds-border-subtle-02);
  }

  .fjs-table-nav-button {
    border-left: 1px solid var(--cds-border-subtle-02);
  }
}

// Dynamic lists ////////////

.fjs-container .fjs-form-field-dynamiclist {
  .fjs-repeat-row-container .fjs-repeat-row-remove-icon-container {
    border-radius: 0;
    color: var(--cds-button-danger-secondary);
    min-width: rem(40);
    min-height: rem(40);

    &:hover {
      color: var(--cds-text-on-color);
      background-color: var(--cds-button-danger-hover);
    }
  }

  .fjs-repeat-row-container .fjs-repeat-row-remove:focus-visible .fjs-repeat-row-remove-icon-container {
    outline: 2px solid var(--cds-focus);
    outline-offset: 1px;
    border-radius: 0;
  }

  .fjs-repeat-render-footer {
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 0;
  }

  .fjs-repeat-render-add,
  .fjs-repeat-render-collapse {
    min-height: rem(40);
    padding-inline-end: rem(15);

    &:focus {
      outline: 2px solid var(--cds-focus);
      outline-offset: 1px;
      border: none;
      border-radius: 0;
    }

    &:hover {
      color: var(--cds-link-primary-hover);
      background-color: var(--cds-background-hover);
    }
  }
}
