.ant-modal .ant-modal-close {
  top: 0.5rem;
  color: var(--white);
}

.ant-modal .ant-modal-header {
  padding: 0.5rem 1rem;
  margin: 0;
  background-color: var(--color-primary);

  .ant-modal-title {
    text-align: center;
  }
}

.ant-modal .ant-modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.ant-modal .ant-modal-footer {
  background-color: var(--color-background);
  border-top: 1px solid var(--color-border);
  margin-top: 0;
  padding-top: 12px;
}

.custom-confirm-modal {
  .ant-modal-confirm-btns {
    padding: 16px;
    border-top: 1px solid var(--color-background-overlay);
    margin-top: 0;
  }
}
