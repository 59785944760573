.gray-select.ant-select {
  .ant-select-selector {
    background-color: var(--color-background-2);
  }
}

.custom-prefix {
  .ant-input-prefix {
    color: var(--color-text-hint);
  }
}

.package-fee-create {
  .ant-form-item-label {
    label {
      color: var(--color-text-secondary);
      font-weight: 500;
    }
  }
}
