.select-all {
  .ant-select-item.ant-select-item-option:hover {
    background-color: #0000000a;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background-color: transparent;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active:hover {
    background-color: #0000000a;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: transparent;
  }

  .ant-select-item-option-state {
    display: none;
  }
}
