.custom-range-picker {
  .hide-range-value {
    .ant-picker-input,
    .ant-picker-input-placeholder > input {
      color: transparent;
    }

    .ant-picker-range-separator {
      display: none;
    }

    .ant-picker-disabled {
      .ant-picker-input {
        input[disabled] {
          color: transparent;
        }
      }
    }
  }

  .range-picker-value-display {
    position: absolute;
    margin-top: -28px;
    margin-left: 10px;
    font-size: 14px;
    white-space: nowrap;
    pointer-events: none;
    width: 65%;

    &.disable {
      color: var(--color-gray-300);
    }
  }
}
