.custom-select-all {
  &.ant-select-dropdown {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled):has(
        + .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
      ) {
      border-start-start-radius: 0;
      border-start-end-radius: 0;
    }
  }
}
