.solution-container {
  .ant-modal-body {
    background-color: var(--color-background-2);
  }
}

.ant-form-only-validate {
  margin-bottom: 0;

  .ant-form-item-control-input {
    display: none;
  }
}

.timeline-dot:not(:last-child)::after {
  content: '';
  height: 25px;
  border-left: 1px solid #ccc;
  position: absolute;
  left: 9px;
  transform: translateX(-50%);
  top: 50%;
  margin-top: 5px;
}

.custom-timeline {
  li.ant-timeline-item.ant-timeline-item-last {
    padding-bottom: 4px;

    .ant-timeline-item-content {
      min-height: 20px;
    }
  }
}

.ant-select-rounded-full {
  .ant-select-selector {
    border-radius: 40px;
  }
}

.custom-dot:not(:last-child) {
  &::after {
    content: '';
    height: 35px;
    border-left: 1px solid #ccc;
    position: absolute;
    left: 8px;
    transform: translateX(-50%);
    top: 60%;
    margin-top: 5px;
  }
}

.select-custom-tags {
  .ant-select-arrow {
    display: none;
  }

  .ant-select-selector {
    overflow-y: auto;
    align-items: start;
    padding-top: 4px;

    .ant-select-selection-placeholder {
      top: 14px;
    }
  }
}
