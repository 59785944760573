.custom-step {
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      .ant-steps-icon {
        .ant-steps-icon-dot {
          background: var(--color-neutral);
        }
      }
    }
  }

  &.ant-steps {
    &.ant-steps-vertical {
      .ant-steps-item {
        .ant-steps-item-content {
          min-height: 35px;

          .ant-steps-item-title {
            color: var(--color-text);
          }
        }

        .ant-steps-item-container {
          .ant-steps-item-tail {
            &::after {
              height: 100%;
            }
          }
        }
      }

      &.ant-steps-dot {
        &.ant-steps-small {
          .ant-steps-item-icon {
            margin-top: 8px;
          }

          .ant-steps-item {
            .ant-steps-item-container {
              .ant-steps-item-tail {
                top: 6px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
