@import 'antd/antd';
@import 'tailwind/tailwind';
@import 'common/scrollbar';
@import 'gam-solution';
@import '@/modules/end-user-insight-solution/styles';
@import '@/modules/chat/styles';
@import '@/modules/product-document/styles';
@import '@/modules/package-fee/styles';
@import '@/modules/dx/styles';
@import '@/modules/product-solution/styles';
@import '@/modules/public-modules/styles';
@import '@/modules/ar-invoice/styles';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
}

html,
body {
  max-width: 100vw;
  font-size: 16px;
}

// Hide body overflow to prevent pull to refresh on mobile
@media (max-width: 768px) {
  body {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.chat-content {
  display: none;
}

.chat-wrapper:hover .chat-content {
  display: flex;
}

::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
  display: block;
}

.dark::view-transition-old(root) {
  z-index: 1;
}

.dark::view-transition-new(root) {
  z-index: 999;
}

::view-transition-old(root) {
  z-index: 999;
}

::view-transition-new(root) {
  z-index: 1;
}
