/**
 * file-drop.css
 */
.drop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  padding: 50px;

  background: rgba(255, 255, 255, 0.9);

  z-index: 1000;
}

.drop-overlay .box {
  text-align: center;
  border: dashed 4px #ccc;
  height: 100%;
  width: 100%;
  display: table;
}

.drop-overlay .label {
  font-size: 26px;
  color: #888;
  margin: auto;

  display: table-cell;
  vertical-align: middle;
}

.fjs-pgl-root {
  --color-button-hover: var(--color-grey-225-10-95);

  --color-section-border: var(--color-grey-225-10-55);
  --color-section-header-background: var(--color-grey-225-10-95);
  --color-section-header-border-bottom: var(--color-grey-225-10-75);

  --color-palette-container-background: var(--color-grey-225-10-97);
  --color-palette-container-border: var(--color-grey-225-10-80);

  --color-properties-container-background: var(--color-white);

  --color-text: var(--color-grey-225-10-15);

  --font-family: 'IBM Plex Sans', sans-serif;
  --font-family-monospace: 'IBM Plex Mono', monospace;

  --modal-zindex: 100;
}

.fjs-pgl-root {
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;

  font-family: var(--font-family);
}

.fjs-pgl-main {
  width: calc(100% - 450px);
  height: 100%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 70% 30%;
}

/**
 * Palette
 */
.fjs-pgl-palette-container {
  height: 100%;
  background-color: var(--color-palette-container-background);
  border-right: solid 1px var(--color-palette-container-border);
  overflow-y: auto;
  flex-shrink: 0;
}

.fjs-pgl-palette-container .fjs-palette-container {
  height: 100%;
  border-right: none;
}

/**
 * Properties Panel
 */
.fjs-pgl-properties-container {
  position: relative;
  display: flex;
  height: 100%;
  width: 250px;
  overflow-y: auto;
  background-color: var(--color-properties-container-background);
}

.fjs-pgl-properties-container .fjs-properties-container {
  --properties-panel-width: 250px;
}

.fjs-pgl-properties-container .bio-properties-panel {
  --font-family: var(--font-family);
}

/**
 * Section
 */
.fjs-pgl-section {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.fjs-pgl-section:nth-child(1),
.fjs-pgl-section:nth-child(2) {
  border-bottom: double 3px var(--color-section-border);
}

.fjs-pgl-section:nth-child(1),
.fjs-pgl-section:nth-child(3) {
  border-right: double 3px var(--color-section-border);
}

.fjs-pgl-section .header {
  background: var(--color-section-header-background);
  font-size: 1rem;
  margin: 0;
  line-height: 2em;
  padding: 0.2em 0.5em;
  border-bottom: solid 1px var(--color-section-header-border-bottom);
}

.fjs-pgl-section .header-items {
  float: right;
}

.fjs-pgl-section .body {
  flex: 1;
  overflow: hidden;
}

.fjs-pgl-modal {
  z-index: var(--modal-zindex);
}

.fjs-pgl-modal .fjs-pgl-modal-backdrop {
  background: rgba(0 0 0 / 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--modal-zindex);
}

.fjs-pgl-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  width: 500px;
  overflow-y: auto;
  max-height: 80%;
  transform: translate(-40%, -50%);
  z-index: var(--modal-zindex);

  font-size: 14px;

  padding: 20px;

  background: var(--color-white);
}

.fjs-pgl-modal-content textarea {
  width: 100%;
  height: 300px;
  overflow: auto;
}

.fjs-pgl-modal-footer {
  margin-top: 20px;

  text-align: right;
}

.fjs-pgl-form-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.fjs-pgl-form-container .fjs-container .cds--grid .cds--row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.fjs-pgl-form-container .fjs-form-editor {
  width: 100%;
}

.fjs-pgl-text-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  position: relative;
}

.fjs-pgl-text-container > .cm-editor {
  font-size: 0.9em;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fjs-pgl-text-container > .cm-editor > .cm-scroller {
  font-family: var(--font-family-monospace);
  overflow: auto !important;
}

.fjs-pgl-form-container > .fjs-container {
  overflow: auto;
}

.fjs-pgl-button {
  color: var(--color-text);
  background-color: var(--color-white);

  border-width: 1px;
  border-style: solid;
  border-radius: 3px;

  font-family: var(--font-family);
}

.fjs-pgl-button-default {
  padding: 7px 10px;
}

.fjs-pgl-button:hover {
  background-color: var(--color-button-hover);
}

.fjs-pgl-button + .fjs-pgl-button {
  margin-left: 1em;
}

.fjs-pgl-parent {
  width: 100%;
  height: 100%;
}
