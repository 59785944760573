.extra-actions {
  button {
    border-color: var(--color-primary);
    color: var(--color-primary);
  }
}

.table-ar-invoice.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected {
  > .ant-table-cell {
    background: none;
  }

  > .ant-table-cell-row-hover {
    background: var(--color-background-1);
  }
}
